<template>
  <div>
    <div class="mb-1">
      <b-button
        variant="warning"
        @click="handleBack"
      >
        {{$t('message.back')}}
      </b-button>
    </div>

    <b-row>
      <b-col lg="7">
        <b-card v-if="clientData !== null">
          <b-row >
            <b-col md="5">
              <div class="d-flex mb-1">
                <b-avatar size="5rem"></b-avatar>
                <div class="p-1">
                  <h4>
                    {{$t('data.firstNmae')}}: {{clientData.first_name}}
                  </h4>
                </div>
              </div>
            </b-col>

            <b-col cols="12" class="pt-2">
              <b-row>
                 <b-col cols="6">
                  <div class="d-flex">
                    <b-icon icon="person" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('message.Full_name')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6">
                  <p class="fs-17">{{clientData.full_name}}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="telephone" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('data.mobile_number')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.contacts[0].number}}</p>
                </b-col>

                <b-col cols="6" v-if="clientData.contacts[1].number !== null" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="telephone" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('data.mobile_number')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" v-if="clientData.contacts[1].number !== null" class="mt-cols">
                  <p class="fs-17">{{clientData.contacts[1].number}}</p>
                </b-col>  

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('data.date_birthday')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.birthdate}}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="book-half" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('message.Passport_serial')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.passport_serial}}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="book-half" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('message.Passport_number')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.passport_number}}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('data.given_date')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.given_date}}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{$t('data.date_birthday')}}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{clientData.birthdate}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="5">
        <b-card v-if="data !== null">
          <h4>{{$t('message.insurance_text')}}</h4>
          <hr>
          <b-row>
            <b-col cols="6" >
              <div class="d-flex">
                <b-icon icon="person" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('Author')}}:</p>
              </div>
            </b-col>
            <b-col cols="6">
              <b class="fs-17">{{data.author.full_name}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Currency')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.currency.name}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('data.payment_type')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.payment_type.name_ru}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="file-earmark" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('data.legal_collection')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.is_legal_collection ? this.$t('data.yes') : this.$t('data.not')}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet2" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Actual_price')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{parseInt(data.actual_price)}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet2" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Market_price')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{parseInt(data.market_price)}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="signpost-split" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Country')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.country.name_ru}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Start_date')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.start_date}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.End_date')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.end_date}}</b>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {getInsuranceById} from '@/api/insurance/index'
  import moment from 'moment'

  export default {
    name: "Tours-Details",
    data() {
      return {
        id: this.$route.params.insuranceDetailsId,
        data: null,
        clientData: null,
      }
    },
    created() {
      getInsuranceById(this.$route.params.insuranceDetailsId).then( res => {
        this.data = res.data.data;
        this.data.start_date = moment(this.data.start_date).format('DD-MM-YYYY')
        this.data.end_date = moment(this.data.end_date).format('DD-MM-YYYY')
        this.clientData = res.data.data.client;
        this.clientData.contacts = JSON.parse(this.clientData.contacts);
        this.clientData.given_date = moment(this.clientData.given_date).format('DD-MM-YYYY');
      })
    },
    methods: {
      handleBack() {
        // this.$router.push({name: 'insurance'})
        this.$router.go(-1)
      },
    }
  }
</script>
